$(function () {
    const slideTime = 100; //ms
    var timeout = null;

    var nav = $('#mainNav > div > ul');
    var parentItem = $('#mainNav > div > ul > li');
    var burger = $('#mainNavBurger');
    var expander = $('#mainNav li .expander, #auxNavLinks li .expander');
    var expanderAnchor = expander.prev("a");
    var headerFollowLink = $('#headerFollowLink');
    var headerContactLink = $('#headerContactLink');
    var headerSearchLink = $('#headerSearchLink');
    var followPanel = $('#headerFollow');
    var contactPanel = $('#headerContactUs');
    var searchPanel = $('#headerSearch');
    var geoFinderLink = $('#headerContactBranchFinderGeoLink');
    var $searchField = $('#search');
    var $valMsg = $searchField.next('span');
    var isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;

    expanderAnchor.addClass('expanderAnchor');

    //show menu when burger is clicked
    burger.click(function () {
        if (nav.is(':visible')) {
            hideAllPanels();
            burger.removeClass('open');
        }
        else {
            burger.addClass('open');
        }
        nav.slideToggle(slideTime);
    });

    expanderAnchor.click(function (event, stopProppagation) {
        if (stopProppagation) {
            return false;
        }

        console.log('this.next ul: ', );

        if (($(this).parent('li').hasClass('selected')//as per requirements, second click should navigate to the page
            && !$(this).hasClass('aux-nav-header'))//unless it is an aux menu item with no pages under
            || $(this).parent('li').is(':hover'))//or it is a desktop parent menu item
        {
            location.href = $(this).attr('href');
        }
        else {
            $(this).next('.expander').trigger('click', true);//else expand by triggering the click on the expander

            return false;
        }
    });

    //expand menu children
    expander.click(function (event, expandingFromAuxiliarMenuLink) {
        //var navAnchor = $(this).prev('a');
        //var isAuxMenuItem = navAnchor.hasClass('aux-nav-header');

        //if (isAuxMenuItem) {
        //    if (!expandingFromAuxiliarMenuLink) {
        //        navAnchor.trigger('click', true);

        //        return false;
        //    }
        //} else {
        //    deselectAuxMenuItems();
        //}

        var parent = $(this).parent('li');
        var menu = parent.children('section');
        var subnavs = $('#mainNav > div > ul section:not(#headerSearch)').not(menu);
        var mainMenuListItems = $('#mainNav > div > ul:first-of-type li').not(parent);
        select(parent, !menu.is(':visible'));
        subnavs.slideUp(slideTime);
        mainMenuListItems.removeClass('selected');
        menu.slideToggle({
            duration: slideTime, start: function () {
                jQuery(this).css('display', 'inline-block');
            }
        });
    });

    ////follow panel reveal
    //headerFollowLink.hover(function (e) {
    //    if (isDesktop()) { showPanel(followPanel, e); }
    //});

    ////contact panel reveal
    //headerContactLink.mouseenter(function (e) {
    //    if (isDesktop()) { showPanel(contactPanel, e); }
    //});

    ////search panel reveal
    //headerSearchLink.hover(function (e) {
    //    if (isDesktop()) { showPanel(searchPanel, e); }
    //});

    ////Branch search geolocator link
    //geoFinderLink.hover(function (e) {
    //    if (isDesktop()) {
    //        e.preventDefault();
    //        getLocation();
    //    }
    //});


    //follow panel reveal
    headerFollowLink.click(function (e) {
        showPanel(followPanel, e);
    });

    //contact panel reveal
    headerContactLink.click(function (e) {
        showPanel(contactPanel, e);
    });

    //search panel reveal
    headerSearchLink.click(function (e) {
        e.preventDefault();
        if(!searchPanel.is(':visible'))showPanel(searchPanel, e);
        $('#search').focus();
    });

    //Branch search geolocator link
    geoFinderLink.click(function (e) {
        e.preventDefault();
        getLocation();
    });

    //Close all menus on resize
    if (isAndroid) {
        $(window).on('orientationchange', function () {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                deselectAll();
                hideAllPanels();
            }, 100);
        });
    }
    else {
        
    }

    $('#btnSearch').click(function (e) {
        if ($searchField.val().length < 2) {
            e.preventDefault();
            //show validation message
            $valMsg.removeClass('hidden');
            $searchField.focus();
        }
    });
    $searchField.keyup(function () {
        if ($(this).val().length > 1) {
            $valMsg.addClass('hidden');
        }
    });


    ////////////////////////////////////////////////////////
    function isDesktop() {
        return !expander.is(':visible');
    }

    function showPanel(panel, e) {
        e.preventDefault();
        var otherPanels = $('#mainNav section:not(#headerSearch)').not(panel);
        var li = panel.parent('li');
        panel.css('z-index', 1001);
        otherPanels.css('z-index', 1000);
        $('#auxNavLinks li').removeClass('selected');
        

        otherPanels.slideUp(slideTime);
        if (panel.is(':visible')) li.removeClass('selected');
        else li.addClass('selected');
        panel.slideToggle(slideTime);
    }

    function hideAllPanels() {
        $('#mainNav section:not(#headerSearch)').slideUp(slideTime);
    }

    function getLocation() {
        var geo = swf.geolocator;
        geo.getLocation(
            //success
            function (position) {
                $('#headerContactBranchFinderError').addClass('hidden');
                $('#headerContactBranchLatLong').val(position.latitude + "," + position.longitude);
                $('#frmHeaderBranchFinder').submit();
            },
            //failure
            function () {
                $('#headerContactBranchFinderError').removeClass('hidden');
            }
        );
    }

    function select(elem, show) {
        if (show) elem.addClass('selected');
        else elem.removeClass('selected');
    }

    function deselectAll() {
        parentItem.removeClass('selected');
        $('ul', parentItem).removeAttr('style');
    }

    $(window).scroll(function () {
        var amt = $(document).scrollTop();
        if (amt > 98) $('body').addClass('nav-pinned');
        else $('body').removeClass('nav-pinned');

        //Local branch scrollpsy nav
        if (amt > 540) $('body').addClass('nav-pinned2');
        else $('body').removeClass('nav-pinned2');
    });

    function deselectAuxMenuItems() {
        $('#auxNavLinks li').removeClass('selected');
        $('#auxNavLinks li section').hide();
    }
});