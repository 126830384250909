$(window).bind("load resize", function () {
    resizeIframes();
});

function resizeIframes() {
    $('.RTE iframe, #videoModal iframe').each(function () {
        var v = $(this);
        var iframeInitW = v.attr('width');
        var iframeInitH = v.attr('height');
        var ratio = iframeInitH / iframeInitW;
        var iframeW = v.width();
        var iframeNewW = iframeW * ratio;
        v.css('height', iframeNewW);
    });
}