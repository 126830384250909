var swf = swf || {};

swf.geolocator = {

    $pc: null,
    $loc: null,
    $frm: null,

    position: {
        latitude: 0,
        longitude: 0
    },

    init: function () {
        var that = this;

        //event handlers
        $('.geoLink').click(function (e) {
            e.preventDefault();
            setElems($(this));
            that.setLocation();
            that.$pc.val('');
            $('.geoMessage', that.$frm).removeClass('hidden');
            $(this).addClass('hidden');
        });

        $('.geoPostcode').change(function () {
            setElems($(this));
            $('.geoMessage').addClass('hidden');
            $('.geoLink').removeClass('hidden');
            that.$loc.val('');
        });

        $('.geoSearchButton2').click(function (e) {
            e.preventDefault();
            setElems($(this));
            that.$frm.submit();
        });

        $('.geoSearchButton').click(function (e) {
            e.preventDefault();
            setElems($(this));
            if (that.$pc.val().length > 0) {
                that.getLocFromPostcode(function () {
                    swf.eventIndex.getResultsForPage(1)
                });
            }
            else swf.eventIndex.getResultsForPage(1)
        });

        function setElems($t) {
            that.$frm = $t.parents('form');
            that.$pc = $('.geoPostcode', that.$frm);
            that.$loc = $('.geoLatLong', that.$frm);
        }
    },

    getLocation: function (success, error) {
        navigator.geolocation.getCurrentPosition(
            //success
            function (position) {
                position.latitude = position.coords.latitude;
                position.longitude = position.coords.longitude;
                return success(position);
            },
            //error
            function () {
                if (error) {
                    return error();
                }
            }
        );
    },


    getLocFromPostcode: function(success) {
        //Check we have a valid postcode
        var that = this;
        var pc = this.$pc.val();
        var pcMatch = pc.match(/^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/gi);
        var pcIsValid = pcMatch !== null && pcMatch.length > 0;
        var ocMatch = pc.match(/^[A-Z]{1,2}[0-9][0-9A-Z]?$/gi);
        var ocIsValid = ocMatch !== null && ocMatch.length > 0;

        if (pc.length >= 5 && pcIsValid) {
            //hide any messages
            $('.geoError', that.$frm).addClass('hidden');
            //Get latlong from postcode using postcodes.io
            $.getJSON('https://api.postcodes.io/postcodes/' + pc.trim(), function (data) {
                that.$loc.val(data.result.latitude + "," + data.result.longitude);
                if (success !== undefined) success();
            });
        }
        else if (pc.length >= 2 && pc.length <= 4 && ocIsValid) {
            //hide any messages
            $('.geoError', that.$frm).addClass('hidden');
            //Get latlong from postcode using postcodes.io
            $.getJSON('https://api.postcodes.io/outcodes/' + pc.trim(), function (data) {
                that.$loc.val(data.result.latitude + "," + data.result.longitude);
                if (success !== undefined) success();
            });
        }
        else {
            //if invalid entry, reset the latlong
            that.$loc.val('');
        }
    },

    setLocation: function () {
        var that = this;
        var geo = swf.geolocator;
        geo.getLocation(
            //success
            function (position) {
                $('.geoError', that.$frm).addClass('hidden');
                that.$loc.val(position.latitude + "," + position.longitude);
            },
            //failure
            function () {
                $('.geoError', that.$frm).removeClass('hidden');
                $('.geoMessage', that.$frm).addClass('hidden');
            }
        );

    }


};