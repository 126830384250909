$(function () {
    $('a[href*=vimeo],a[href*=youtube],a[href*="youtu.be"]', 'main').click(function (e) {
        e.preventDefault();
        var md = $('#videoModal');
        md.html(getVideoEmbedHtml($(this).attr('href')));
        md.css('display', 'flex');
        md.modal({
            backdrop: 'static'
        });
        var videoIframe = md.find('iframe');
        md.on('hidden.bs.modal', function () {     
            videoIframe.attr('src', '');
        });
        resizeIframes();
    });

    $('body').on($.modal.OPEN, function (event, modal) {
        resizeIframes();
    });
    $('body').append('<div id="videoModal" class="modal"></div>');
});

function getVideoEmbedHtml(url) {
    var id = '';
    var match = null;
    var regex;

    //YouTube
    if (url.indexOf("youtube.com") > -1) {
        regex = /v=([a-zA-Z_0-9\-]+)/gi;
        match = regex.exec(url);
    }
    else if (url.indexOf("youtu.be") > -1) {
        regex = /youtu.be\/([a-zA-Z_0-9\-]+)/gi;
        match = regex.exec(url);
    }
    if (match !== null && match.length > 0) id = match[1];
    if (id !== '') {
        return '<span class="close-modal" data-dismiss="modal">×</span><iframe width="640" height="360" src="//www.youtube.com/embed/' + id + '" frameborder="0" allowfullscreen></iframe>';
    }

    //Vimeo
    if (url.indexOf("vimeo.com") > -1) {
        regex = /\/([0-9]+)/gi;
        match = regex.exec(url);
    }
    if (match !== null && match.length > 0) id = match[1];
    if (id !== '') {
        return '<span class="close-modal" data-dismiss="modal">×</span><iframe src="https://player.vimeo.com/video/' + id + '?color=ffffff" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
    }
}