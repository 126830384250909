//var swf = swf || {};

//swf.siteSearch = {
//    searchBox: $('#siteSearch input[type=search]'),
//    searchButton: $('#siteSearch button'),

//    init() {
//        var ss = this;
//        this.searchButton.click(function (e) {
//            e.preventDefault();
//            ss.search();
//        });
//    },

//    search() {
//        var terms = this.searchBox.val();

//    }
//};

////initialise
//swf.siteSearch.init();