var swf = swf || {};

swf.forms = {

    init: function () {
        var that = this;

        var $customSelects = $('.custom-select');

        $customSelects.each(function (index, item) {
            validateSelectedValue($(item));
        });

        $customSelects.on("change", function () {
            validateSelectedValue($(this));
        });

        function validateSelectedValue($select) {
            if ($select.val() === "") {
                $select.addClass("default-option");
            } else {
                $select.removeClass("default-option");
            }
        }

        $('input[type=text], input[type=search], input[type=number], input[type=email], input[type=tel], input[type=date],input[type=password], textarea, select')
            .focus(function () {
                $(this).parent('div').addClass('active');
            })
            .keydown(function () {
                $(this).parent('div').addClass('active');
            })
            .blur(function () {
                var t = $(this);
                setTimeout(function () {
                    var p = t.parent('div');
                    if (t.is('select')) {
                        //for select boxes only revert label position if the option's text is blank
                        if ($('option:selected', t).text().length === 0) {
                            p.removeClass('active');
                        }
                    }
                    else if (t.val().length === 0) {
                        p.removeClass('active');
                    }
                }, 200);
            });

        //revalidate email addresses when user corrects a field shown as invalid
        $('input[type=email],input[type=tel]').keyup(function () {
            //if the validation error message is showing
            if ($(this).next('span').text().length > 0) {
                //revalidate the field
                $(this).valid();
            }

        });

        $('#Title').change(function () {
            if ($(this).val() === 'Other') $('#OtherTitle').parent().removeClass('hidden');
            else $('#OtherTitle').parent().addClass('hidden');
        });

        $('#FundingRecipient').change(function () {
            if ($(this).val() === 'Branch') $('#fundingRecipientNameField').removeClass('hidden');
            else $('#fundingRecipientNameField').addClass('hidden');
        });

        $('.radioReveal input[type=radio]').change(function () {
            var reveal = $(this).parents('div').first().next();
            if ($(this).val() === 'true') reveal.removeClass('hidden');
            else reveal.addClass('hidden');
        });

        $('#HowMuchDoYouPlanToRaise').on('input', function () {
            $(this).val($(this).val().replace(/[^\d]/, ''));
        });

        this.autosizeTextareas();

        //this.sizeDropdowns();
    },

    autosizeTextareas: function () {
        var hidDiv = $(document.createElement('div')),
            content = null;

        hidDiv.addClass('hiddendiv');
        $('body').append(hidDiv);

        $('form textarea').each(function () {
            var t = $(this);
            t.css({ 'overflow': 'hidden', 'resize': 'none' });
            t.on('keyup', function () {
                if (t.val().length === 0) t.css('height', 'auto');
                else {
                    content = t.val();
                    hidDiv.css('width', t.width());

                    content = content.replace(/\n/g, '<br>');
                    hidDiv.html(content + '<br class="lbr">');

                    t.css('height', hidDiv.outerHeight());
                }
            });
        });
    },

    positionDirtyFieldLabels: function () {
        $('input[type=text], input[type=search], input[type=number], input[type=email], input[type=tel], input[type=date],input[type=password], textarea, select').each(function () {
            if ($(this).val().length > 0) $(this).parent('div').addClass('active');
        });
    }

    //sizeDropdowns: function () {
    //    $('form select').each(function () {
    //        var t = $(this);
    //        var l = t.prev('label');
    //        if (l.length > 0) {
    //            lw = l.width();
    //            tw = t.width();
    //            if (lw > tw) t.width(lw + 30);
    //        }
    //    });
    //}

};

$(function () {
    swf.forms.init();
});

window.addEventListener('DOMContentLoaded', function () {
    if (window.wpnc) {
        var $blockLabels = $("input[type='radio'], input[type='checkbox']");
        new wpnc.SelectionButtons($blockLabels);
    }

    //activate any pre-filled fields
    swf.forms.positionDirtyFieldLabels();

    
    
});